var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"custom-select",class:{
    _open: _vm.isOpenItems,
    'default-color': _vm.isColorDefault,
    'mobile-dropdown': _vm.isMobileDropdown,
  }},
    !_vm.isMobile
      ? { mouseenter: _vm.handlerOpenItems, mouseleave: _vm.handlerCloseItems }
      : { click: _vm.handlerOpenItems }
  ),[_c('div',{staticClass:"selected",class:{ 'no-select': _vm.noSelect }},[(_vm.svgIcon && !_vm.isHeaderPopupMobile)?_c('svg-icon',_vm._b({staticClass:"icon-select"},'svg-icon',_vm.svgIcon,false)):_vm._e(),_vm._v(" "),(!_vm.noSelect && !_vm.isHideTitleMobile)?_c('span',[_vm._v(_vm._s(_vm.selectedItem.val))]):_vm._e(),_vm._v(" "),(_vm.title && !_vm.isHideTitleMobile)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._v(" "),(!_vm.isHideTitleMobile)?_c('svg-icon',{staticClass:"arrow",attrs:{"name":"arrow","width":"6px","height":"10px","stroke":"#a3a3a3"}}):_vm._e(),_vm._v(" "),(_vm.isMobile && _vm.isOpenItems && !_vm.isMobileDropdown)?_c('svg-icon',{staticClass:"selected__btn-close",attrs:{"name":"close","width":"18.45px","height":"18.45px","stroke":"#838383"},on:{"click":function($event){$event.stopPropagation();return _vm.handlerCloseItems.apply(null, arguments)}}}):_vm._e()],1),_vm._v(" "),_c('ul',{staticClass:"items",class:{ _open: _vm.isOpenItemsInfo, _left: _vm.isLeft }},_vm._l((_vm.optionsList),function(option){return _c('li',{key:option.id,class:{ _active: !_vm.noSelect && option.id === _vm.selectedItem.id },on:{"click":function($event){$event.stopPropagation();return _vm.handlerSetActive(option.id)}}},[_c('span',[_vm._v(_vm._s(option.val))])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }