/*
TODO
[+] - решить проблему закрытия селекта при mouseleave
*/
<template>
  <!--  -->
  <div
    class="custom-select"
    :class="{
      _open: isOpenItems,
      'default-color': isColorDefault,
      'mobile-dropdown': isMobileDropdown,
    }"
    v-on="
      !isMobile
        ? { mouseenter: handlerOpenItems, mouseleave: handlerCloseItems }
        : { click: handlerOpenItems }
    "
  >
    <div class="selected" :class="{ 'no-select': noSelect }">
      <svg-icon
        v-if="svgIcon && !isHeaderPopupMobile"
        v-bind="svgIcon"
        class="icon-select"
      />
      <span v-if="!noSelect && !isHideTitleMobile">{{ selectedItem.val }}</span>
      <span v-if="title && !isHideTitleMobile">{{ title }}</span>
      <svg-icon
        v-if="!isHideTitleMobile"
        class="arrow"
        name="arrow"
        width="6px"
        height="10px"
        stroke="#a3a3a3"
      />
      <svg-icon
        v-if="isMobile && isOpenItems && !isMobileDropdown"
        class="selected__btn-close"
        name="close"
        width="18.45px"
        height="18.45px"
        stroke="#838383"
        @click.stop="handlerCloseItems"
      />
    </div>
    <ul class="items" :class="{ _open: isOpenItemsInfo, _left: isLeft }">
      <li
        v-for="option of optionsList"
        :key="option.id"
        :class="{ _active: !noSelect && option.id === selectedItem.id }"
        @click.stop="handlerSetActive(option.id)"
      >
        <span>{{ option.val }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { addRemoveBodyClass } from '@/constants/constFunctions';

export default {
  props: {
    itemId: {
      type: String,
      default: () => '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: () => {},
    },
    svgIcon: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: () => '',
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
    noSelect: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => '',
    },
    notifyId: {
      type: String,
      default: () => '',
    },
    isMobileDropdown: {
      type: Boolean,
      default: false,
    },
    isMobileHeaderTitle: {
      type: Boolean,
      default: false,
    },
    isHideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenItemsInfo: false,
      isOpenItems: false,
      parentId: this.itemId ? this.itemId : '',
    };
  },

  computed: {
    optionsList() {
      return this.options || '';
    },
    selectedItem() {
      return this.selected;
    },
    isColorDefault() {
      return this.color ? this.color : undefined;
    },
    isMobile() {
      return this.windowWidth < 1025 || this.$device.isMobileOrTablet;
    },
    isHeaderPopupMobile() {
      return this.isOpenItems && this.isMobile && this.isMobileHeaderTitle;
    },
    isHideTitleMobile() {
      return this.isHideTitle && !this.isOpenItems && this.isMobile;
    },
  },
  methods: {
    handlerSetActive(optionId) {
      if (!this.notifyId) {
        this.$emit('setActive', optionId, this.parentId);
      } else {
        this.$emit('setActive', optionId, this.parentId, this.notifyId);
      }

      this.handlerCloseItems();
    },
    handlerOpenItems(e) {
      this.isOpenItems = true;
      this.isOpenItemsInfo = true;
      if (this.isMobile) {
        addRemoveBodyClass(this.isOpenItems);
      }
    },
    handlerCloseItems() {
      if (this.isOpenItemsInfo) {
        this.isOpenItems = false;
        this.isOpenItemsInfo = true;
      }
      if (this.isMobile) {
        addRemoveBodyClass(this.isOpenItems);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-select {
  display: inline-block;
  position: relative;
  &._open {
    .items {
      display: block;
    }
    .selected {
      span {
        color: $color-default;
      }
      .arrow {
        transform: rotate(270deg);
      }
      .icon-select {
        fill: $color-default;
      }
    }
  }
  &.default-color {
    .selected span {
      color: $color-default;
    }
    .arrow {
      stroke: $color-default;
    }
    &._open {
      .selected span {
        color: $color-green;
      }
    }
  }
  @include mediaMax($sm) {
    &._open:not(.mobile-dropdown) {
      position: fixed;
      top: 0;
      left: 0;
      width: 101%;
      height: 100vh;
      z-index: 1000;
      margin: 0;
      .selected {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10000;
        margin: 0;
        height: 70px;
        justify-content: space-between;
        padding: 0 31px;
        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #202020 !important;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 80%;
        }
        background-color: #fff;
        box-shadow: 0px 5px 15px rgba(32, 32, 32, 0.05);
      }
      .arrow {
        display: none;
      }
      .items {
        max-height: none;
        position: relative;
        left: 0;
        top: 70px;
        width: 100%;
        z-index: 1000;
        border-radius: 0;
        box-shadow: none;
        margin: 0px 0px 0px 0px;
        height: calc(100vh - 70px);
        li {
          padding-left: 31px;
          padding-right: 31px;
          @include mediaMax($xs) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
    }
  }
  @include mediaMax($xs) {
    &._open:not(.mobile-dropdown) {
      .selected {
        height: 50px;
        padding: 0 20px;
      }
      .items {
        top: 50px;
        height: calc(100vh - 50px);
        z-index: 1000;
      }
    }
  }
}

.custom-select .selected {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 1;
  color: $color-grey-2;
  padding: 0px 0px 5px 0px;
  cursor: default;
  span {
    padding: 0px 10px 0px 0px;
    white-space: nowrap;
    @include mediaMin($md) {
      transition: all 0.3s ease;
    }
  }
  .arrow {
    transform: rotate(90deg);
    @include mediaMin($sm) {
      transition: all 0.3s ease;
    }
  }
}

.custom-select .selected._open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0px 0px;
}

// .custom-select .selected:after {
//   position: absolute;
//   content: '';
//   top: 22px;
//   right: 1em;
//   width: 0;
//   height: 0;
//   border: 5px solid transparent;
//   border-color: #fff transparent transparent transparent;
// }

.custom-select .items {
  border: none;
  min-width: 171px;
  left: auto;
  right: -6px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(32, 32, 32, 0.05);
  cursor: pointer;
  user-select: none;
  position: absolute;
  z-index: 100;
  overflow: hidden;
  display: none;
  max-height: 353px;
  overflow-y: visible;
  @include mediaMin($md) {
    &::-webkit-scrollbar {
      height: 12px;
      width: 5px;
      background: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-green;
      -webkit-border-radius: 1ex;
    }
  }
  &._left {
    right: auto;
    left: -2px;
  }
}

.custom-select .items {
  li {
    transition: all 0.3s ease;
    padding: 21px 25px 21px 20px;
    border-bottom: 0.75px solid $color-light;
    border-top: 0.75px solid $color-light;
    &:first-child {
      border-top: 0;
    }
    span {
      transition: all 0.3s ease;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: $color-default;
      white-space: nowrap;
    }
    &:last-of-type {
      border-bottom: none;
    }
    &:hover {
      background-color: $color-light;
      span {
        color: $color-green;
      }
    }
    &._active {
      background-color: #fff;
      span {
        color: #202020;
        opacity: 0.5;
      }
      &:hover {
        background-color: #fff;
        cursor: default;
        span {
          color: #202020;
          opacity: 0.5;
        }
      }
    }
  }
}

.icon-select {
  fill: $color-grey-2;
  margin: 0px 8px 0px 0px;
}

.mobile-dropdown {
  @include mediaMax($xs) {
    width: 100%;
    .selected {
      width: 100%;
      justify-content: space-between;
    }
    .items {
      width: 100%;
      right: 0;
    }
  }
}
/* .selectHide {
  display: none;
} */
</style>
