


































import { ROUTER_LINK } from '@/constants/constList';

import ContainerXl from '@/components/ui/ContainerXl.vue';
import ButtonV from '@/components/ui/ButtonV.vue';
import { Component, mixins, Vue } from 'nuxt-property-decorator';
import HasBlockContent from '~/mixins/has-block-content';

@Component({
  name: 'MainSection',
  components: {
    ContainerXl,
    ButtonV,
  },
})
export default class MainSection extends mixins(HasBlockContent) {
  get image(): string {
    return this.content.images.main_image1;
  }

  get imageMobile(): string {
    return this.content.images.main_image_mobile1;
  }

  get text(): string {
    return this.content.texts.main_text1;
  }

  get buttons() {
    return [
      {
        title: 'Участвовать в мероприятии',
        url: ROUTER_LINK.events,
      },
      { title: 'Создать мероприятие', url: this.getUrlCreateEvent() },
    ];
  }


  get isMobile() {
    return this.windowWidth < 1025 || this.$device.isMobileOrTablet;
  }


  getUrlCreateEvent() {
    let url = ROUTER_LINK.addEvents.main;
    if (this.$typedStore.state.organization.activeOrganization) {
      url = ROUTER_LINK.organizationAddEvents.main;
    }
    if (this.windowWidth < 1025 || this.$device.isMobileOrTablet) {
      return url;
    }

    return `${url}`;
  }
};
