









import { Component, Vue } from 'nuxt-property-decorator';
import MainSection from '@/components/MainSection/MainSection.vue';
import EventsSection from '~/components/EventsSection/EventsSection.vue';
import AboutSection from '~/components/AboutSection/AboutSection.vue';
import PartnersSlider from '~/components/PartnersSlider/PartnersSlider.vue';
import { Context } from '@nuxt/types';
import { BlockContent, Partner } from '~/declarations/content';

export const getMainContent = async (context: Context) => {
  const [mainContent, partnersContent, partners] = await Promise.all([
    context.app.$api.content.getBlock('main'),
    context.app.$api.content.getBlock('partners'),
    context.app.$api.content.getPartners(),
  ]);
  return {
    mainContent,
    partnersContent,
    partners,
  };
};

@Component({
  auth: false,
  components: {
    MainSection,
    EventsSection,
    AboutSection,
    PartnersSlider,
  },
  fetchOnServer: false,
})
export default class IndexView extends Vue {
  declare mainContent: BlockContent;

  declare partnersContent: BlockContent;

  declare partners: Array<Partner>;

  async asyncData(context: Context) {
    return await getMainContent(context);
  }

  async fetch() {
    await this.$typedStore.dispatch('enums/getProfileEnums');
  }
};
