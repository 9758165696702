









































import ContainerSm from '@/components/ui/ContainerSm.vue';
import ButtonV from '@/components/ui/ButtonV.vue';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';
import { directive } from 'vue-awesome-swiper';
import { Component, mixins, Prop, Vue } from 'nuxt-property-decorator';
import { Partner } from '~/declarations/content';
import HasBlockContent from '~/mixins/has-block-content';

SwiperCore.use([Navigation]);

@Component({
  name: 'MainSlider',
  directives: {
    swiper: directive,
  },
  components: {
    ContainerSm,
    ButtonV,
  },
})
export default class PartnersSlider extends mixins(HasBlockContent) {
  @Prop({ type: Array, required: true })
  partnersList!: Array<Partner>;

  get title(): string {
    return this.content.titles.partner_title1;
  }

  swiperOptions: SwiperOptions = {
    slidesPerView: 5,
    spaceBetween: 56,
    slidesPerGroup: 5,
    mousewheel: {
      invert: false,
      forceToAxis: true,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    speed: 800,
    centeredSlides: false,
    navigation: {
      nextEl: '.partners-next',
      prevEl: '.partners-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1.7,
      },
      400: {
        slidesPerView: 2.5,
      },
      520: {
        slidesPerView: 3.5,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
      1226: {
        slidesPerView: 5,
      },
    },
  };
};
