
<template>
  <ContainerXl class="main-slider">
    <LoaderV v-if="isLoading"></LoaderV>
    <div v-if="!isMobile && eventList.length" class="main-slider__body _swiper">
      <div v-swiper:mainSlider="swiperOptions">
        <div class="swiper-wrapper">
          <div
            v-for="event in eventList"
            :key="event.id"
            class="swiper-slide main-slider__slide"
          >
            <Card :event="event"></Card>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="eventList.length" class="main-slider__body">
      <ul class="main-slider__list">
        <li
          v-for="event in eventList.slice(0, 4)"
          :key="event.id"
          class="main-slider__slide"
        >
          <Card :event="event"></Card>
        </li>
      </ul>
    </div>
    <div v-else>
      <p class="main-slider__no-founded">Мероприятий нет!</p>
    </div>
  </ContainerXl>
</template>

<script>
import ContainerXl from '@/components/ui/ContainerXl.vue';
import Card from '@/components/ui/Card.vue';
import LoaderV from '@/components/ui/LoaderV.vue';

export default {
  name: 'MainSlider',
  components: {
    ContainerXl,
    Card,
    LoaderV,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    eventList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 27,
        slidesPerGroup: 5,
        mousewheel: {
          invert: false,
          forceToAxis: true,
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        speed: 800,
        centeredSlides: false,
        navigation: {
          nextEl: '.main-slider-next',
          prevEl: '.main-slider-prev',
        },
        breakpoints: {
          //   320: {
          // 	  slidesPerGroup: 1,
          //   },
          //   768: {
          // 	  slidesPerGroup: 2,
          //   },
          992: {
            slidesPerGroup: 3,
            allowTouchMove: false,
          },
          1450: {
            slidesPerGroup: 4,
          },
          1800: {
            slidesPerGroup: 5,
          },
        },
        on: {
          afterInit: () => {
            this.setNavigationSlider();
          },
        },
      },
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth < 769 || this.$device.isMobile;
    },
  },
  watch: {
    eventList() {
      if (this.mainSlider && !this.mainSlider.destroyed) {
        this.mainSlider.slideTo(0, 500, true);
      }
    },
  },
  methods: {
    setNavigationSlider() {
      if (this.mainSlider) {
        this.mainSlider.update();
      }
    },
  },
};
</script>
<style lang="scss">
.main-slider {
  .container-xl {
    position: relative;
    @include mediaMax(570px) {
      padding: 0;
    }
  }
  &__list {
    @include mediaMax($sm) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      .main-slider__slide {
        width: 50%;
        padding: 0 10px;
        margin: 0px 0px 20px 0px;
      }
    }
    @include mediaMax(570px) {
      margin: 0;
      .main-slider__slide {
        width: 100%;
        margin: 0px 0px 20px 0px;
        padding: 0;
      }
      .card__icon {
        top: 20px;
        left: 20px;
      }
    }
    li {
      margin: 0px 0px 30px 0px;
      &:last-child {
        margin: 0;
      }
      .card {
        width: 100%;

        &__inner {
          width: 100%;
          @include mediaMax(570px) {
            padding: 20px;
            border-radius: 0;
          }
        }
      }
    }
  }

  &__no-founded {
    width: 100%;
    text-align: center;
  }
}
</style>

