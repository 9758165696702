

























































import { ROUTER_LINK } from '@/constants/constList';

import ContainerSm from '@/components/ui/ContainerSm.vue';
import ButtonV from '@/components/ui/ButtonV.vue';
import MainSlider from '@/components/MainSlider/MainSlider.vue';
import Select from '@/components/ui/Select.vue';
import { Component, mixins } from 'nuxt-property-decorator';
import HasBlockContent from '~/mixins/has-block-content';
import { Event } from '~/declarations/events';

@Component<EventsSection>({
  name: 'EventsSection',
  components: {
    ContainerSm,
    MainSlider,
    ButtonV,
    Select,
  },
  watch: {
    selected() {
      this.isLoading = true;

      clearTimeout(this.timerFetch);
      this.timerFetch = setTimeout(async () => {
        await this.fetchEvent();
      }, this.timeNextFetch);
    },
  },
})
export default class EventsSection extends mixins(HasBlockContent) {
  button = {
    title: 'Калининград',
  };

  eventsLink = ROUTER_LINK.events;

  buttonMore: boolean = true;

  selected: any = {
    id: 0,
    val: 'Все актуальные мероприятия',
  };

  eventList: Array<Event> = [];

  timerFetch: any = null;

  timeNextFetch: number = 300;

  isLoading: boolean = true;

  get title(): string {
    return this.content.titles.main_title1;
  }

  get isMobile(): boolean {
    return this.windowWidth < 1025 || this.$device.isMobileOrTablet;
  }

  get categoryList() {
    const list = [...this.$typedStore.state.enums.eventCategories].map((el) => {
      return {
        id: el.id,
        val: el.name,
      };
    });
    list.unshift({
      id: 0,
      val: 'Все актуальные мероприятия',
    });
    return list;
  }

  get styleSliderButtons() {
    if (this.eventList.length > 0) return '';
    return 'display: none;';
  }

  showLoader() {
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 600);
  }

  setActive(optionId) {
    this.selected = this.categoryList.find((el) => el.id === optionId);
  }

  async fetchEvent() {
    try {
      const params: any = {
        limit: 12,
        offset: 0,
        are_coming: 1,
        new_first: 0,
        only_published: 1,
      };
      if (this.selected.id !== 0) {
        params.category_id = this.selected.id;
      }
      const result = await this.$axios.get('/api/events/', { params });
      this.eventList = result.data.results;
    } catch (e) {
      this.$toast.error(e.message);
    } finally {
      this.isLoading = false;
    }
  }

  async mounted() {
    await this.$typedStore.dispatch('enums/getProfileEnums');
    await this.fetchEvent();
  }
};
