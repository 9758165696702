































import ContainerSm from '@/components/ui/ContainerSm.vue';
import ButtonV from '@/components/ui/ButtonV.vue';
import { ROUTER_LINK } from '@/constants/constList';
import { Component, mixins } from 'nuxt-property-decorator';
import HasBlockContent from '~/mixins/has-block-content';

@Component({
  name: 'AboutSection',
  components: {
    ContainerSm,
    ButtonV,
  },
})
export default class AboutSection extends mixins(HasBlockContent) {
  get image(): string {
    return this.content.images.main_image2;
  }

  get title(): string {
    return this.content.titles.main_title2;
  }

  get description(): string {
    return this.content.texts.main_text2;
  }

  button = {
    title: 'Что делаем',
    link: ROUTER_LINK.about,
  };
};
